import React from 'react'
import { Link } from 'react-router-dom'

const HospitalManagementContainer = () => {
    return (
        <>
            <section className="room-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/executive-customer-care.png" alt="Executive Customer Care | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Executive Customer Care</Link></h3>
                                            <p className='m-2'>
                                                Meet our team of dedicated customer care executives who are committed
                                                to providing exceptional service and support to our patients and their families. With a focus on compassion and efficiency, our executives ensure that every interaction is handled with professionalism and care. They serve as the primary point of contact for inquires, appointments, and support services, guiding patients through their healthcare journey with empathy and understanding. Learn more about their backgrounds, expertise in healthcare administration, and their role in enhancing patient satisfaction and engagement. Our customer care executives are integral to our commitment to providing a seamless and supportive healthcare experience for all.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/gp-consultation.png" alt="GP Consultation | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>GP Consultation</Link></h3>
                                            <p className='m-2'>
                                                At Olepaipai Lifecare, our GP Consultation Service offers a comprehensive approach to your healthcare needs. Our team of skilled general practitioners is committed to delivering personalized care tailored to your specific health concerns. From preventive care and routine checks-ups to the management of chronic conditions and acute illnesses, our Gps provide thorough evaluation and effective treatment plans. We emphasize a patient- centered approach, ensuring that you receive compassionate care and clear communication every step of the way. With our modern facilities and dedicated staff, we strive to support your overall health and well-being.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/mother-and-baby.png" alt="Mother and Baby | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Mother and Baby</Link></h3>
                                            <p className='m-2'>
                                            Our Mother and Baby department is designed to provide exceptional care and support throughout the journey from pregnancy to early childhood. We offer a full spectrum of service, including experts prenatal care, safe and supportive labor and delivery, and comprehensive postnatal care. Our team of obstetricians, midwives, and pediatric specialists work collaboratively to ensure both mother and baby receive the highest standard of care. We focus on creating a nurturing environment that supports the health and well-being of both parents and their newborns, providing guidance, education, and medical expertise to foster a positive start to family life.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/physiotherapy.png" alt="Physiotherapy | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Physiotherapy</Link></h3>
                                            <p className='m-2'>
                                                Olepaipai Lifecare department is dedicated to helping you achieve optimal physical health and recovery. Our licensed physiotherapists use the latest techniques and equipment to provide customized rehabilitation services for
                                                a range of conditions, including musculoskeletal injuries, post- surgical recovery, and chronic pain. Through personalized treatment plans that may include manual therapy, exercise programs, and advanced modalities, we aim to improve your mobility, strength, and overall function. Our goal is to support your rehabilitation journey with evidence- based practices and compassionate care, helping you return to your daily activities with confidence and ease.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/audiology.png" alt="Audiology | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Audiology</Link></h3>
                                            <p className='m-2'>
                                            Our Audiology service specializes in diagnosing and treating hearing and balance disorders with the latest technology and expertise. At Olepaipai Lifecare we perform comprehensive assessments to evaluate your auditory and vestibular function, providing personalized solutions such as hearing aids and therapeutic interventions. We are committed to enhancing your quality of life by addressing hearing loss and balance issues with effective, tailored treatments. Our focus is on delivering compassionate care and empowering you with the tools and knowledge needed to manage your auditory health effectively.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/stress-test.png" alt="Stress Test | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Stress Test</Link></h3>
                                            <p className='m-2'>
                                                The Stress test facility is equipped with advanced technology to evaluate cardiovascular health through controlled physical exertion. Our non-
                                                invasive stress tests help assess how well your heart performs under physical stress, providing critical insights into your cardiovascular fitness and overall heart health. Our skilled cardiologists interpret the result to guide appropriate medical care and lifestyle recommendations. This service is essential for diagnosing potential heart conditions and monitoring existing cardiovascular issues, ensuring that you receive accurate information and targeted interventions for your health.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/endoscopy-&-colonoscopy.png" alt="Endoscopy & Colonoscopy | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>4Endoscopy & Colonoscopy</Link></h3>
                                            <p className='m-2'>
                                            Olepaipai Lifecare offers advanced Endoscopy and Colonoscopy services to provide detailed examinations of the gastrointestinal tract with minimal invasiveness. Using the latest endoscopic technology, our specialists perform thorough evaluation to diagnose and manage a variety of gastrointestinal conditions, including ulcers, polyps, and cancers. These procedures are conducted with precision and care, ensuring patient comfort and safety. Our team provides clear explanations and follow-up care to support your health and well-being, helping you address any issues with confidence and effectiveness.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/4d-Ultrasound.png" alt="4D Ultrasound | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>4D Ultrasound</Link></h3>
                                            <p className='m-2'>
                                            Our 4D Ultrasound service at Olepaipai Lifecare offers an enhanced imaging experience for expectant parents. This advanced technology provides real-time, high- definition images of the fetus, allowing you to see detailed and dynamic views of your baby`s development. 4D Ultrasound not only offers valuable insights into fetal health but also creates memorable moments for families. Our skilled technicians and medical professionals ensure that the procedure
                                            is both informative and enjoyable, providing you with a comprehensive understanding of your baby`s growth and a cherished keepsake of this special time.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/digital-X-Ray.png" alt="Digital X-Ray | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Digital X-Ray</Link></h3>
                                            <p className='m-2'>
                                            Our Digital X-Ray facility utilizes cutting-edge digital imaging technology to deliver high- resolution images with reduced radiation exposure. This advanced system ensures fast and accurate diagnostic results, aiding in the evaluation and management of various medical conditions. Digital X-Rays offer superior image quality and immediate access to results, facilitating timely diagnoses and effective treatment plans. Our radiology team is dedicated to providing precise imaging and clear communication, ensuring that your X-Ray experience is effective and supportive of your overall healthcare needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/laboratory.png" alt="Laboratory | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Laboratory</Link></h3>
                                            <p className='m-2'>
                                                Olepaipai Lifecare Pharmacy offers a comprehensive range of prescription and over- the counter medications, supported by a team of knowledgeable pharmacists. Our pharmacy is dedicated to providing personalized medication management, including medication counseling, dosage adjustments, and drug interactions reviews. We ensure that you receive the right medications with clear instructions and support for adherence. Our goal is to enhance your health outcomes by providing efficient, accurate and compassionate pharmaceutical care tailored to your individual needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/pharmacy.png" alt="Pharmacy | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Pharmacy</Link></h3>
                                            <p className='m-2'>
                                                Olepaipai Lifecare Pharmacy offers a comprehensive range of prescription and over- the counter medications, supported by a team of knowledgeable pharmacists. Our pharmacy is dedicated to providing personalized medication management, including medication counseling, dosage adjustments, and drug interactions reviews. We ensure that you receive the right medications with clear instructions and support for adherence. Our goal is to enhance your health outcomes by providing efficient, accurate and compassionate pharmaceutical care tailored to your individual needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HospitalManagementContainer
