import React from 'react'
import HelmetAsync from '../../CommonElements/HelmetAsync';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Link } from 'react-router-dom';

export const Service = () => {
    return (
        <>
            <HelmetAsync 
                title="Our Services" 
                description="Olepaipai offers a variety of services aimed at empowering individuals and communities. From educational programs and skill development workshops to health initiatives and advocacy, our services are designed to promote sustainable growth and improve quality of life. Explore how we can support you and your community." 
            />
            <Breadcrumbs title="Our  Services" />

            <section className="about-area">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-4 mt-5">
                            <div className="about-text mt-5">
                                <div className="section-title">
                                    <h3><Link>Get the care you need in the home you love.</Link></h3>
                                    <p style={{ fontSize: '1.2rem' }} >
                                        From help a few days a week to 24/7 support for more complex needs, we have an in-life care solution that’s right for you.
                                    </p>
                                    <div className="banner-btn mb-5 text-center">
                                        <a className="default-btn" href="/contact">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/mission-vision.png" alt="Services | Olepaipai Lifecare" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section><br />


            <section className="pricing-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div className="section-title text-center">
                                <h3 style={{ fontSize: '0.9rem' }}>Our Services</h3>
                                <h1 style={{ fontSize: '2rem' }} className='mb-3'>
                                    Exceptional Care at Home for Older Adults
                                </h1>
                                <p>
                                    First, we get to know you and your care needs, then we design a flexible Care Plan just for you.<br />
                                    You’ll get the in-life care solution you need—and always be informed and in control.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing">
                                <div className="package-name">
                                    <h3>Life care</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        From a few hours a day of help to 24/7 support, we will design a Care Plan to meet your specific needs. Our high-quality in-life care includes:
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Companionship</p></span>
                                    <span><p>Help At Home</p></span>
                                    <span><p>Respite Care</p></span>
                                    <span><p>24/7 Care</p></span>
                                    <span><p>Live-In Care</p></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing best-offer">
                                <div className="package-name">
                                    <h3>Memory Care at Home*</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        Our caregivers are expertly trained to support people living with any type of cognitive decline from mild memory problems to advanced dementia.
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Mild Cognitive Impairment (MCI)</p></span>
                                    <span><p>Alzheimer’s Disease</p></span>
                                    <span><p>Vascular Dementia</p></span>
                                    <span><p>Lewy Body Dementia</p></span>
                                    <span><p>Frontotemporal Dementia</p></span>
                                    <span><p>Parkinson’s Disease Dementia</p></span>
                                    <p className='p-2'>
                                        * Personal care and non-medical support for people living with cognitive impairment
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing">
                                <div className="package-name">
                                    <h3>Specialized Care</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        We support people living with specific medical conditions and unique needs, providing the best care so our clients can live safely at home.
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Parkinson’s Disease</p></span>
                                    <span><p>Heart Health</p></span>
                                    <span><p>After A Stroke</p></span>
                                    <span><p>Cancer</p></span>
                                    <span><p>End-Of-Life Care</p></span>
                                    <span><p>Hospital To Home</p></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="about-area bg-light pt-5 pb-5">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-md-8">
                            <div className="room-facilities">
                                <h3 className="room-details-title pb-4">We help with daily activities and much more.</h3>
                                <div className="single-facility">
                                    <span><i className="zmdi zmdi-check"></i>Companionship</span>
                                    <span><i className="zmdi zmdi-check"></i>Medication Reminders</span>
                                    <span><i className="zmdi zmdi-check"></i>Grocery Shopping</span>
                                    <span><i className="zmdi zmdi-check"></i>Meal Prep and Nutrition</span>
                                    <span><i className="zmdi zmdi-check"></i>Transportation and Errands</span>
                                    <span><i className="zmdi zmdi-check"></i>Cognitive Engagement</span>
                                </div>
                                <div className="single-facility">
                                    <span><i className="zmdi zmdi-check"></i>Personal Care and Hygiene</span>
                                    <span><i className="zmdi zmdi-check"></i>Staying Active</span>
                                    <span><i className="zmdi zmdi-check"></i>Light Housekeeping</span>
                                    <span><i className="zmdi zmdi-check"></i>Social Activities and Connection</span>
                                    <span><i className="zmdi zmdi-check"></i>Hobbies and Passions</span>
                                    <span><i className="zmdi zmdi-check"></i>Meaningful Activities</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="room-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div className="section-title text-center">
                                <h3> Types of life care</h3>
                                <p>
                                    Our team-based approach to care and our proprietary Balanced Care Method™ set us apart from other life care providers. Wherever you call home, whatever type of care you need, you’ll get a customized Care Plan focused on joy, engagement, and meaningful activities. How can we help you?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Memory Care at Home*</Link></h3>
                                            <p className='m-2'>
                                                When a loved one living with memory problems, cognitive issues, or dementia needs support to stay at home safely and comfortably, we can help by providing person-centered care. Our caregivers have the professional training and experience to manage challenging behaviours with skill and compassion. We can assist your loved one with daily activities, personal care, and social engagement while supporting their quality of life.
                                            </p>
                                            <p>
                                                * Personal care and non-medical support for people living with cognitive impairment
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Support for Chronic Conditions</Link></h3>
                                            <p className='m-2'>
                                                As a chronic condition advances, living at home can be more challenging but just as fulfilling with the right support. Our expertly trained caregivers can help older adults with a chronic condition or age-related illness, maintain their lifestyle and independence even as their symptoms progress. We can assist you or your loved one with daily activities, personal care, and staying socially engaged with friends and family.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>24/7 Complex Care</Link></h3>
                                            <p className='m-2'>
                                                When a loved one has an advanced illness, is severely weak or bed bound, or needs round-the-clock care, many families prefer to bring professional caregivers into the home rather than moving their loved one to a facility. We build a small team of trained, professional caregivers to deliver seamless care so your loved one can get the full-time support needed to be safe and comfortable, living with dignity in their own home.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Post-Hospitalization Support</Link></h3>
                                            <p className='m-2'>
                                                Following surgery, a hospitalization, or stay in a rehab facility, most people would rather be at home in their own bed, surrounded by the people and things they know and love. Our Hospital-to-life care solution sets you up to recover safely and comfortably at home. From hospital discharge to full recovery, we’ll be by your side, providing the physical and emotional support you need—so you can relax, rest, and focus on getting better.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>End-of-Life Care</Link></h3>
                                            <p className='m-2'>
                                                A clinical, unfamiliar setting is the last place most people want to be during the final stages of life. We offer end-of-life care so your loved one can safely and comfortably stay at home, even while on hospice. When treatment is no longer an option or the end of life is near, our caregivers can provide comfort and compassionate care, create a calm and peaceful environment, and offer practical and emotional support to the individual and family members.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Frailty or Fall Risk Support</Link></h3>
                                            <p className='m-2'>
                                                When a loved one becomes frail or develops physical weakness or balance problems, the risk of falling increases. Even one fall can cause a loss of confidence and serious injury. Our caregivers have professional training with mobility support and can help with daily activities, safe movement, and strength building. With the right care, your loved one can stay in the home they love, maintaining their quality of life—with a reduced risk of falls.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Companion Care</Link></h3>
                                            <p className='m-2'>
                                                When an older adult is feeling isolated, withdrawing from their routines, or may need some assistance to live comfortably on their own, we can help them continue to enjoy their lifestyle and favourite activities in the home they know and love. Our caregivers will provide quality companionship while assisting with daily activities, including light housekeeping, grocery shopping, healthy meal preparation, and transportation to social outings.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Help Around the Home</Link></h3>
                                            <p className='m-2'>
                                                Designed to support older adults who are mostly independent and healthy, this type of care provides a helping hand around the house—and also can be an easy introduction to care at home. Whether it’s changing light bulbs or doing the shopping, we can help you or a loved one stay independent with fewer household responsibilities, living well in the home you love.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Life Care Planning</Link></h3>
                                            <p className='m-2'>
                                                If you’re feeling overwhelmed as you try to navigate financial, legal, and health planning decisions, you’re not alone. Planning for future care needs can be challenging—and it’s hard to know where to turn for accurate information and advice. We recommend working with one of our professional Care Managers who will help you understand your options and the associated costs and also connect you with a team of advisors so you can make the best decisions and plans for your values and goals.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Adults with Disabilities Support </Link></h3>
                                            <p className='m-2'>
                                                If you or a loved one is living with developmental or other disabilities, we can help. Our full-life care companions have professional training to support adults of any age, whether they’re living independently or with family members. We can provide personal care, supportive care, and respectful, safe supervision—or whatever assistance is needed to live your best life in your own home.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Not Sure of Care Needs </Link></h3>
                                            <p className='m-2'>
                                                Not sure what type of care you need or just beginning this journey? We’ll be your expert guide along the way—drawing on our 20 years of experience. We’ll learn about your situation, then help you determine the right level of care. Every Care Plan we design is based on the Balanced Care Method™, our proprietary approach to care. This holistic approach involves more than helping with tasks and daily activities—we also focus on supporting overall wellness.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="about-area bg-light pt-4 pb-4">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-md-8">
                            <div className="room-facilities">
                                <h3 className="room-details-title"> Memory Care at Home</h3>
                                <p>
                                With the right level of support, your loved one with memory issues or more advanced dementia can live comfortably and safely in the home they know—even as their condition progresses. Our caregivers are expertly trained to support clients living with Alzheimer’s disease and other types of dementia and cognitive decline. And every caregiver is backed by our in-house team of memory care experts who provide training and oversight and are available to families for consultation as needed.
                                </p>
                                <div className="single-facility">
                                    <span><i className="zmdi zmdi-check"></i>Memory problems and confusion</span>
                                    <span><i className="zmdi zmdi-check"></i>Mild Cognitive Impairment (MCI)</span>
                                    <span><i className="zmdi zmdi-check"></i>Alzheimer’s disease</span>
                                    <span><i className="zmdi zmdi-check"></i>Vascular dementia</span>
                                </div>
                                <div className="single-facility">
                                    <span><i className="zmdi zmdi-check"></i>Lewy body dementia</span>
                                    <span><i className="zmdi zmdi-check"></i>Frontotemporal dementia</span>
                                    <span><i className="zmdi zmdi-check"></i>Parkinson’s disease dementia</span>
                                    <span><i className="zmdi zmdi-check"></i>Undiagnosed cognitive decline</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="room-area pt-90">
                <div className="container pb-3">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="section-title">
                                <h3  className='text-center'> Specialized Care</h3>
                                <p className='text-justify'>
                                    At every level of care, we’ll treat you like a person—not a patient.<br /><br />

                                    Many age-related illnesses and conditions come with a range of symptoms that progress over time. That’s why in-life care for people with a specific illness is called specialized care. It’s often more challenging, requiring specialized training for the best outcomes and care experience.<br /><br />

                                    Our caregivers are expertly trained to support people living with chronic medical conditions, providing personalized care so our clients can continue to live safely in their own home. We help with daily activities and support the unique care needs of people with mobility issues, cognitive decline, heart disease, and cancer, or need assistance following a stroke, surgery, hospitalization, or other serious medical event.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>MParkinson’s Disease</Link></h3>
                                            <p className='m-2'>
                                                While Parkinson’s affects everyone differently, caring for a loved one with this disease at home becomes more challenging over time as their symptoms progress. We can provide mobility support and transportation to Parkinson’s physical therapy activities and medical appointments as well as help with healthy meal preparation, personal care, and safe, daily exercise, which is so important to slowing the progression of symptoms. Our caregivers are also trained to support clients with Parkinson-related mood, memory, and balance issues—and give family members time to rest and recharge.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Heart Health</Link></h3>
                                            <p className='m-2'>
                                                Heart disease, the leading cause of death around the world, refers to several types of heart conditions, including Coronary artery disease, Arrhythmia, Atherosclerosis, and Cardiomyopathy. Whether you or a loved one has heart disease or cardiovascular disease, which affects the blood vessels, and you’re struggling with dizziness and shortness of breath or weakness in the limbs, we can help. Our caregivers are trained to provide a heart-healthy diet with balanced nutrition to help prevent future cardiac events and assist with transportation, personal care, and other daily activities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>After a Stroke</Link></h3>
                                            <p className='m-2'>
                                                Recovering from a stroke can be an extremely challenging process. Patients often must overcome physical, cognitive, and emotional hurdles as they relearn speech and movement. Physical therapy can be frustrating and daily activities that once were easy are now difficult or impossible to do without help. Our caregivers can provide the support needed to ease your challenges and improve your outcome.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Cancer</Link></h3>
                                            <p className='m-2'>
                                                During treatment or while recovering at home, we can make life easier and more comfortable for people fighting cancer. Our caregivers can provide transportation to and from medical appointments, pick up prescriptions, and help with nutrition and meal preparation, medication reminders and staying hydrated, personal care, and light housekeeping. And they can provide moral support to keep spirits high.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>End-of-Life Caree</Link></h3>
                                            <p className='m-2'>
                                                When a curative treatment is no longer possible or the end of life is near, we can help. Our compassionate caregivers are trained to provide comfort, support palliative care, and improve the end-of-life experience for the individual and their family. We also understand how to collaborate with hospice teams or other in-home health providers, integrating our services for a seamless care experience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Hospital to Home</Link></h3>
                                            <p className='m-2'>
                                                Following a serious medical incident, surgery, or hospitalization, setting up assistance at home supports a healthier recovery, improving outcomes and reducing the risks of readmission. Our experienced caregivers can get you home safely, pick up prescriptions, help with medication reminders and staying hydrated, nutrition and meal preparation, mobility support, personal care, and much more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="advertise-area bg-2 overlay-dark pt-5 pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-8">
                        <div className="advertise-text">
                            <h2  className='text-white'>
                                We’re passionate about helping older adults live in their own home as they age, improving the quality of life for our clients—and those who love them.
                            </h2>
                        </div>
                    </div>
                    </div>
                </div>
            </section>


            <section className="about-area bg-light pt-5 pb-5">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-md-8">
                            <div className="room-facilities">
                                <h2 className="room-details-title pb-4">
                                    <p>
                                        Whether you`re in need of routine check-ups, specialized treatments, or personalized care plans, our experts team of healthcare professionals is here to provide you with the highest standard of medical excellence
                                    </p>
                                </h2>
                                <div className="single-facility">
                                    <span><i className="zmdi zmdi-check"></i>G.P Consultation</span>
                                    <span><i className="zmdi zmdi-check"></i>Physiotherapy</span>
                                    <span><i className="zmdi zmdi-check"></i>Stress test/ Echo</span>
                                    <span><i className="zmdi zmdi-check"></i>Audiology</span>
                                </div>
                                <div className="single-facility">
                                    <span><i className="zmdi zmdi-check"></i>Specialised Consultation</span>
                                    <span><i className="zmdi zmdi-check"></i>Mother & Baby Wellness</span>
                                    <span><i className="zmdi zmdi-check"></i>Antenatal Care</span>
                                    <span><i className="zmdi zmdi-check"></i>Laboratory</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="room-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div className="section-title text-center">
                                <h3>  Our Exclusive Services</h3>
                                <p>
                                    Beyond our comprehensive range of essential healthcare services , Olepaipai Lifecare proudly offers and exclusive selection of specialized treatments and personalized care options.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Loyalty Points | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Loyalty Points</Link></h3>
                                            <p className='m-2'>
                                                Accumulate reward based on your patronage, redeemable for discounts, freebies, or special perks, encouraging continued engagement and loyalty.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Telemedicine | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Telemedicine</Link></h3>
                                            <p className='m-2'>
                                            Connect securely for personalized consultations and second opinions from wherever you are, saving time and ensuring quality care tailored to your needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Home care Services | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Home care Services</Link></h3>
                                            <p className='m-2'>
                                            We offer personalized doctor homecare services, including physiotherapy and laboratory services right at your doorstep.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="4D Ultrasound | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>4D Ultrasound</Link></h3>
                                            <p className='m-2'>
                                            First of its kind, providing detailed, real-time image in four dimensions plus movement, offering an enhanced prenatal bonding experience for expecting parents.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Digital X-Ray | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Digital X-Ray</Link></h3>
                                            <p className='m-2'>
                                            The latest in technology, faster processing, enhanced image manipulation, and reduced radiation exposure facilitating more accurate diagnoses and treatment planning.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Pediatric Echo | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Pediatric Echo</Link></h3>
                                            <p className='m-2'>
                                            State of the art technology to examine and evaluate the structure and function of the heart in children, aiding in the diagnosis and management of various cardiac conditions in pediatric patients.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Neonatal Hearing Test | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Neonatal Hearing Test</Link></h3>
                                            <p className='m-2'>
                                            Ensure the health and well-being of your newborn with our advanced screening that detects genetic and metabolic conditions early, offering peace of mind and proactive care for your baby`s future.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )    
}
export default Service;