import React from 'react'
import HelmetAsync from '../CommonElements/HelmetAsync';
import Breadcrumbs from '../components/Breadcrumbs';
import MedicalTeamContainer from '../components/MedicalTeam/MedicalTeamContainer';


const MedicalTeam = () => {
    return (
        <>
            <HelmetAsync 
                title="Medical" 
                description="Join Olepaipai in empowering communities through accessible healthcare, education, and sustainable initiatives. Your contributions directly support our mission to provide essential services, foster positive change, and improve the quality of life for underserved populations. Together, we can build a brighter, healthier future—every contribution makes a meaningful difference."
            />
            <Breadcrumbs title="Our Medical Team" />

            <MedicalTeamContainer />
            
        </>
    )
}

export default MedicalTeam
