import React from 'react'

const ContactInfo = () => {
    return (
        <>
            <h4 className="contact-title">Contact info</h4>
            <div className="contact-text">
                <p><span className="c-icon"><i className="zmdi zmdi-phone"></i></span><span className="c-text">+254103333332/ +254769333330</span></p>
                <p><span className="c-icon"><i className="zmdi zmdi-email"></i></span><span className="c-text">info@olepaipai.org</span></p>
                <p><span className="c-icon"><i className="zmdi zmdi-pin"></i></span><span className="c-text">Daima Towers, 14TH Flr. Uganda Road, Eldoret</span></p>
            </div>
            <h4 className="contact-title">social media</h4>
            <div className="link-social">
                <a href="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></a>
                <a href="https://www.rss.com/"><i className="zmdi zmdi-rss"></i></a>
                <a href="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></a>
                <a href="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></a>
                <a href="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></a>
            </div>
        </>
    )
}

export default ContactInfo
