import React from 'react'
import Bookings from '../../CommonElements/Bookings';
import { Link } from 'react-router-dom';


const MedicalTeamContainer = () => {
    return (
        <>
            <section className="about-area">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3><Link>Lab Specialists</Link></h3>
                                    <p>
                                        Meet our team of dedicated lab specialists who are at the forefront of scientific innovation and patient care. With expertise in various laboratory disciplines, our specialists contribute to accurate diagnostics, research advancements, and treatment efficacy. Each specialist bring a wealth of knowledge and technical skill to their role, ensuring precision and reliability in every test and analysis conducted. Explore their backgrounds, areas of specialization, and the pivotal role they play in supporting healthcare excellence and improving patient outcomes. Whether in Pathology, Microbiology, hematology, or other specialized fields, our lab specialists are committed to upholding the highest standards of quality and contributing to the advancement of medical science.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/our-medical-team.png" alt="Our Medical Team | Olepaipai" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-area">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/our-medical-team.png" alt="Our Medical Team | Olepaipai" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3><Link>Radio Sonographer</Link></h3>
                                    <p>
                                        Discover our team of expert radiologists who specialize in medical imaging and diagnostic interpretation. With advanced training and experience in interpreting X-rays, and other imaging modalities, our radiologists play a crucial role in diagnosing and treating a wide range of medical conditions. Their expertise ensures accurate and timely diagnoses, guiding effective treatment plans and improving patient outcomes. Learn more about each radiologist`s specialized areas, research interests, and commitment to leverage cutting- edge technology for the benefit of our patients. Our radiologists are dedicated to excellence in medical imaging and are integral members of our healthcare team , providing invaluable support to clinicians and patients alike.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section><br /><br />

            <Bookings />
        </>
    )
}

export default MedicalTeamContainer;
