import React from 'react'
import HelmetAsync from '../CommonElements/HelmetAsync';
import Breadcrumbs from '../components/Breadcrumbs';
import Bookings from '../CommonElements/Bookings';
import HospitalManagementContainer from '../components/HospitalManagement/HospitalManagementContainer';

const HospitalManagement = () => {
    return (
        <>
            <HelmetAsync 
                title="Hospital Management" 
                description="Explore Olepaipai's comprehensive hospital services designed to provide exceptional care. From General Consultations to specialized care, we offer state-of-the-art facilities and compassionate support for your health and wellness needs." 
            />
            <Breadcrumbs title="Hospital Management" />

            <HospitalManagementContainer />

            <Bookings />
        </>
    )
}

export default HospitalManagement;
