import React from 'react'

const index = () => {
    return (
        <>
            <section className="bg-2 overlay-dark-2 pt-5 pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-8">
                            <div className="advertise-text">
                                <h2 className='text-white breadcrumb-text text-center'>
                                    <h2 className='text-white pb-3'>Bookings</h2>
                                    <h3 className='text-white'>For Bookings or any related questions and concerns don`t hesitate to reach out to us:</h3>
                                </h2>
                            </div>
                            <div className="banner-btn mb-5 text-center">
                                <a className="default-btn" href="/contact">Book Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default index
