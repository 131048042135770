import React from 'react'
import About from '../components/Home/About'
import WhoBenefits from '../components/Home/WhoBenefits'
import Sliders from '../components/Home/Sliders'
import Bookings from '../CommonElements/Bookings'
import { Link } from 'react-router-dom'

export const Home = () => {

    return (
        <>
            <Sliders />
            <About />

            <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>Our Medical Team</h3>
                                    <p>              
                                        At Olepaipai, our team of Lab Specialists and Radio Sonographers is dedicated to delivering exceptional healthcare services through precision, expertise, and innovation.

                                        Our Lab Specialists are experts in disciplines like pathology, microbiology, and hematology, ensuring every test and analysis meets the highest standards of accuracy and reliability. They play a vital role in diagnostics, research advancements, and treatment effectiveness, contributing significantly to improving patient outcomes.

                                        Our Radio Sonographers specialize in advanced medical imaging techniques, including X-rays and ultrasounds, providing timely and accurate diagnoses that guide effective treatment plans. With state-of-the-art technology and a commitment to excellence, they support clinicians and patients alike in achieving better health outcomes.  
                                    </p>
                                    <div className="banner-btn mb-5">
                                        <a className="default-btn" href="/medical-team">Our Medical Team</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/our-medical-team.png" alt="About Olepaipai" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-1 overlay-dark-2 pt-5 pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-8">
                            <div className="advertise-text">
                                <h2 className='text-white breadcrumb-text text-center'>
                                    <h3 className='text-white'>
                                        From hospital discharge to full recovery, we’ll be by your side, providing the physical and emotional support you need.<br /><br /> Let us help you on your road to recovery
                                    </h3>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="room-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="section-title text-center">
                                <h3>Hospital Management</h3>
                                <p>
                                    At Olepaipai Lifecare, we provide a wide range of exceptional healthcare services designed to meet the diverse needs of our patients. With state-of-the-art facilities, advanced technology, and a compassionate team of professionals, we are dedicated to enhancing your health and wellness through personalized care and efficient services.<br />
                                    Explore our specialized offerings:
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/executive-customer-care.png" alt="Executive Customer Care | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Executive Customer Care</Link></h3>
                                            <p className='m-2'>
                                                Meet our team of dedicated customer care executives who are committed
                                                to providing exceptional service and support to our patients and their families. With a focus on compassion and efficiency, our executives ensure that every interaction is handled with professionalism and care. They serve as the primary point of contact for inquires, appointments, and support services, guiding patients through their healthcare journey with empathy and understanding. Learn more about their backgrounds, expertise in healthcare administration, and their role in enhancing patient satisfaction and engagement. Our customer care executives are integral to our commitment to providing a seamless and supportive healthcare experience for all.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/gp-consultation.png" alt="GP Consultation | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>GP Consultation</Link></h3>
                                            <p className='m-2'>
                                                At Olepaipai Lifecare, our GP Consultation Service offers a comprehensive approach to your healthcare needs. Our team of skilled general practitioners is committed to delivering personalized care tailored to your specific health concerns. From preventive care and routine checks-ups to the management of chronic conditions and acute illnesses, our Gps provide thorough evaluation and effective treatment plans. We emphasize a patient- centered approach, ensuring that you receive compassionate care and clear communication every step of the way. With our modern facilities and dedicated staff, we strive to support your overall health and well-being.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div class="col-lg-5 col-md-6">
                                        <Link><img src="assets/img/room/mother-and-baby.png" alt="Mother and Baby | Olepaipai Lifecare" /></Link>
                                    </div>
                                    <div class="col-lg-7 col-md-6 align-self-center">
                                        <div class="room-list-text">
                                            <h3><Link>Mother and Baby</Link></h3>
                                            <p className='m-2'>
                                            Our Mother and Baby department is designed to provide exceptional care and support throughout the journey from pregnancy to early childhood. We offer a full spectrum of service, including experts prenatal care, safe and supportive labor and delivery, and comprehensive postnatal care. Our team of obstetricians, midwives, and pediatric specialists work collaboratively to ensure both mother and baby receive the highest standard of care. We focus on creating a nurturing environment that supports the health and well-being of both parents and their newborns, providing guidance, education, and medical expertise to foster a positive start to family life.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-btn mb-5 text-center">
                            <a className="default-btn" href="/hospital-management">Explore More</a>
                        </div>
                    </div>
                </div>
            </section>

            <WhoBenefits />

            <section className="about-area bg-light pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>Get the care you need in the home you love.</h3>
                                    <p>              
                                    From help a few days a week to 24/7 support for more complex needs, we have an in-life care solution that’s right for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div className="section-title text-center">
                                <h3 style={{ fontSize: '0.9rem' }}>Our Services</h3>
                                <h1 style={{ fontSize: '2rem' }} className='mb-3'>
                                    Exceptional Care at Home for Older Adults
                                </h1>
                                <p>
                                    First, we get to know you and your care needs, then we design a flexible Care Plan just for you.<br />
                                    You’ll get the in-life care solution you need—and always be informed and in control.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing">
                                <div className="package-name">
                                    <h3>Life care</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        From a few hours a day of help to 24/7 support, we will design a Care Plan to meet your specific needs. Our high-quality in-life care includes:
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Companionship</p></span>
                                    <span><p>Help At Home</p></span>
                                    <span><p>Respite Care</p></span>
                                    <span><p>24/7 Care</p></span>
                                    <span><p>Live-In Care</p></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing best-offer">
                                <div className="package-name">
                                    <h3>Memory Care at Home*</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        Our caregivers are expertly trained to support people living with any type of cognitive decline from mild memory problems to advanced dementia.
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Mild Cognitive Impairment (MCI)</p></span>
                                    <span><p>Alzheimer’s Disease</p></span>
                                    <span><p>Vascular Dementia</p></span>
                                    <span><p>Lewy Body Dementia</p></span>
                                    <span><p>Frontotemporal Dementia</p></span>
                                    <span><p>Parkinson’s Disease Dementia</p></span>
                                    <p className='p-2'>
                                        * Personal care and non-medical support for people living with cognitive impairment
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing">
                                <div className="package-name">
                                    <h3>Specialized Care</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        We support people living with specific medical conditions and unique needs, providing the best care so our clients can live safely at home.
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Parkinson’s Disease</p></span>
                                    <span><p>Heart Health</p></span>
                                    <span><p>After A Stroke</p></span>
                                    <span><p>Cancer</p></span>
                                    <span><p>End-Of-Life Care</p></span>
                                    <span><p>Hospital To Home</p></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-btn pt-5 text-center">
                        <a className="default-btn" href="/our-services">Explore More</a>
                    </div>
                </div>
            </section>

            <section className="advertise-area bg-1 overlay-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="advertise-text">
                                <h1 style={{ fontSize: '1.6rem' }}>Our Hospital-to-life care solution sets you up to recover safely and comfortably at home.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-area bg-light pt-5 pb-5">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-md-8">
                            <div className="room-facilities">
                                <h2 className="room-details-title pb-4">
                                    <p>
                                        Whether you`re in need of routine check-ups, specialized treatments, or personalized care plans, our experts team of healthcare professionals is here to provide you with the highest standard of medical excellence
                                    </p>
                                </h2>
                                <div className="single-facility">
                                    <span><i className="zmdi zmdi-check"></i>G.P Consultation</span>
                                    <span><i className="zmdi zmdi-check"></i>Physiotherapy</span>
                                    <span><i className="zmdi zmdi-check"></i>Stress test/ Echo</span>
                                    <span><i className="zmdi zmdi-check"></i>Audiology</span>
                                </div>
                                <div className="single-facility">
                                    <span><i className="zmdi zmdi-check"></i>Specialised Consultation</span>
                                    <span><i className="zmdi zmdi-check"></i>Mother & Baby Wellness</span>
                                    <span><i className="zmdi zmdi-check"></i>Antenatal Care</span>
                                    <span><i className="zmdi zmdi-check"></i>Laboratory</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="room-area pt-90 pb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div className="section-title text-center">
                                <h3>Our Exclusive Services</h3>
                                <p>
                                    Beyond our comprehensive range of essential healthcare services , Olepaipai Lifecare proudly offers and exclusive selection of specialized treatments and personalized care options.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Loyalty Points | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Loyalty Points</Link></h3>
                                            <p className='m-2'>
                                                Accumulate reward based on your patronage, redeemable for discounts, freebies, or special perks, encouraging continued engagement and loyalty.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Telemedicine | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Telemedicine</Link></h3>
                                            <p className='m-2'>
                                            Connect securely for personalized consultations and second opinions from wherever you are, saving time and ensuring quality care tailored to your needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 p-2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                            <Link><img src="assets/img/logo/logo1.png" alt="Home care Services | Olepaipai logo" height={100} /></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 align-self-center">
                                        <div className="room-list-text">
                                            <h3><Link>Home care Services</Link></h3>
                                            <p className='m-2'>
                                            We offer personalized doctor homecare services, including physiotherapy and laboratory services right at your doorstep.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-btn pt-2 pb-5 text-center">
                                <a className="default-btn" href="/our-services">Explore More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Bookings />
            
        </>
    )
}
export default Home;